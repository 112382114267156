class SignzyVideoService {
	constructor({
		domain,
		vbind,
		conferenceName,
		userInformation,
		htmlElement,
		useStunTurn,
		directRemote,
		directLocal,
		unMuteCb,
		isAdmin,
		remoteVideoMuteCB,
		videoQuality,
		jitsiLogLevel,
		jitsiEvents,
		disableJitsiLogs,
		jitsiCodec
	}) {
		//Required Variables that manages streams of enduser and banker
		this.localTracks = [];
		this.remoteTracks = {};
		this.arrayStream = [];
		this.tempTrack = undefined;
		this.recordingErrCount = 0;

		this.confOptions = {
			p2p: {
				enabled: true,
				backToP2PDelay: 0
			},
			openBridgeChannel: true,
			videoQuality: {
				preferredCodec: jitsiCodec ?? "VP8",
				maxBitratesVideo: {
					VP8: {
						low: 500000,
						standard: 1000000,
						high: 1500000
					},
					VP9: {
						low: 1000000,
						standard: 1500000,
						high: 2500000
					}
				}
			}
		};

		let serviceUrl = undefined;
		let hosts = {};

		const httpsRegex = /^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i;
		const vbindMatches = vbind.match(httpsRegex); // vbind will be null if no match is found
		const domainMatches = domain.match(httpsRegex); // domain will be null if no match is found

		if (vbindMatches) {
			serviceUrl = `https://${vbindMatches[1]}/http-bind`;
		} else {
			serviceUrl = `https://${vbind}/http-bind`;
		}

		if (domainMatches) {
			hosts = {
				domain: `${domainMatches[1]}`,
				muc: `conference.${domainMatches[1]}`
			};
		} else {
			hosts = {
				domain: `${domain || "jitsi.signzy.app"}`,
				muc: `conference.${domain || "jitsi.signzy.app"}`
			};
		}

		this.connectionOptions = {
			hosts: hosts,
			serviceUrl: serviceUrl,
			// clientNode: "https://signzy.com",
			// enableLipSync: true,
			// enableXmppWebsocket: true,
			// enableWebsocketKeepAlive: true,
			xmppPing: {
				threshold: 15,
				// maxDropouts: 8,
				interval: 5000,
				timeout: 3000
			},
			useStunTurn
		};

		this.initOptions = {
			disableAudioLevels: true,
			disableNS: false,
			disableRtx: true,
			disableSimulcast: true,
			preferredCodec: jitsiCodec ?? "VP8",
			// useTurnUdp: useStunTurn || false,
			userInfo: userInformation || {
				email: "",
				displayName: ""
			}
		};
		this.jitsiLogLevel = jitsiLogLevel || "ERROR";

		JitsiMeetJS.init(this.initOptions);
		JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels[this.jitsiLogLevel]);

		//Creating a new connection
		this.connection = new JitsiMeetJS.JitsiConnection(null, null, this.connectionOptions);

		//Adding connection events
		this.initiateConnection(this.connection);

		//Conference/streams related variable
		this.conferenceName = conferenceName;
		this.htmlElement = htmlElement;
		this.directLocal = directLocal;
		this.directRemote = directRemote;

		//Required flags
		this.isAdmin = isAdmin;
		this.isRetryCase = false;
		this.p2pRejoin = false;
		this.facingModeUser = true;
		this.localAudioMuted = false;
		this.localVideoMuted = false;

		//MISC flags and states
		this.backCameraId = undefined;
		this.frontCameraId = undefined;
		this.videoQuality = videoQuality || "720";
		this.disableJitsiLogs = disableJitsiLogs || false;
		this.jitsiEvents = jitsiEvents ? jitsiEvents : false;

		//Callbacks and other functions
		this.unMuteCb = unMuteCb || false; //Sent from RE side to restart recording
		this.remoteVideoMuteCB = remoteVideoMuteCB;
		this.isRecording = false;
		//TODO: This is for the new stats feature it will take statsHandler function
		// this.statsHandler = statsHandler;

		//TODO: Can be removed, it's for screensharing functionality
		// this.isScreenShared = false;
		// this.screenShareAudioStream = [];
		// this.screenSharingStatus = screenSharingStatus;
	}

	sleep = (delay) => {
		return new Promise((resolve) => {
			setTimeout(resolve, delay);
		});
	};

	generateTrackOptions = (deviceType) => {
		const options = {};
		// options["minFps"] = 22;
		// options["maxFps"] = 30;
		// options["resolution"] = this.videoQuality;

		const isIphone = /iPhone|iPad|iPod/i.test(navigator.userAgent);
		if (isIphone) {
			const constraints = {
				video: {
					aspectRatio: 16 / 9,
					frameRate: 24,
					height: {
						ideal: 720,
						max: 1080,
						min: 480
					}
				}
			};
			options["constraints"] = constraints;
		} else {
			options["constraints"] = {
				video: {
					frameRate: 24,
					height: {
						ideal: 720,
						max: 1080,
						min: 480
					}
				}
			};
		}

		switch (deviceType) {
			case "onlyVideo":
				options["devices"] = ["video"];
				if (this.frontCameraId === undefined || this.backCameraId === undefined) {
					// For webview front or back will be undefined as label is empty while capturing all availabe devices
					// therefore we shall select video stream using facing mode instead of device id.
					// Did it in this way in order to prevent any issues from occuring in vkyc journey outside webview
					options["facingMode"] = this.facingModeUser ? "user" : "environment";
				} else {
					options["cameraDeviceId"] = this.facingModeUser ? this.frontCameraId : this.backCameraId;
				}
				break;
			//TODO: Uncomment this part of the code for the screensharing feature
			// case "screenShare":
			// 	options["devices"] = ["desktop"];
			// 	options["constraints"] = {
			// 		video: {
			// 			aspectRatio: 16 / 9,
			// 			frameRate: 30,
			// 			height: {
			// 				ideal: 1080,
			// 				max: 1080,
			// 				min: 720
			// 			}
			// 		}
			// 	};
			// 	// this.room.setDesktopSharingFrameRate(30);
			// 	break;
			default:
				options["devices"] = ["audio", "video"];
		}

		return options;
	};

	setLocalDeviceIds = async () => {
		return new Promise((resolve, reject) => {
			navigator.mediaDevices
				.enumerateDevices()
				.then((devices) => {
					devices.forEach((device) => {
						if (device.kind === "videoinput" && device.label.includes("front")) {
							console.log(`[!] Setting device Id, ${device.label} id = ${device.deviceId}`);
							this.frontCameraId = device.deviceId;
						}
						if (device.kind === "videoinput" && device.label.includes("back")) {
							console.log(`[!] Setting device Id, ${device.label}  id =  ${device.deviceId}`);
							this.backCameraId = device.deviceId;
						}
					});
					resolve();
				})
				.catch((err) => {
					console.error("[!] setLocalDeviceIds:: Error while iterating through navigator mediaDevices! ", err);
					if (this.jitsiEvents && !this.disableJitsiLogs) {
						this.jitsiEvents("logs", `setLocalDeviceIds - ${err}`);
					}
					reject(err);
				});
		});
	};

	initiateConnection = (connection) => {
		try {
			connection.addEventListener(JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED, async (resp) => {
				console.log("[!] Connection event:: Connection Estabilished! ", resp);
				console.log("Creating a room with name:: ", this.conferenceName);

				//Making sure that the local tracks are created before joining the room
				await JitsiMeetJS.createLocalTracks(this.generateTrackOptions(), true)
					.then(this.onLocalTracks)
					.catch((err) => {
						console.error("[!] CreateLocalTracks event, error while creating local tracks! ", err);
					});

				await this.setLocalDeviceIds();

				this.room = this.connection.initJitsiConference(this.conferenceName, this.confOptions);
				this.onConnectionSuccess(this.room);
			});
			connection.addEventListener(JitsiMeetJS.events.connection.CONNECTION_FAILED, this.onConnectionFailed);
			connection.addEventListener(JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED, this.onDisconnect);
			connection.connect();
		} catch (err) {
			console.error("[!] Initiate Connection:: Error while adding connection events! ", err);
			if (this.jitsiEvents && !this.disableJitsiLogs) {
				this.jitsiEvents("logs", `initiateConnection - ${err}`);
			}
		}
	};

	onConnectionSuccess = (room) => {
		// Experimental changes adding the tracks as soon as connection is established
		this.localTracks.forEach(async (track) => await room.addTrack(track));

		//Events
		room.on(JitsiMeetJS.events.conference.TRACK_ADDED, this.onRemoteTrack);
		room.on(JitsiMeetJS.events.conference.CONFERENCE_JOINED, this.onConferenceJoined);
		room.on(JitsiMeetJS.events.conference.USER_JOINED, this.onUserJoin);
		room.on(JitsiMeetJS.events.conference.USER_LEFT, this.onUserLeft);
		room.on(JitsiMeetJS.events.conference.P2P_STATUS, this.onP2PStatusChanged);
		//TODO: Add this below feature later down the line
		// room.on(JitsiMeetJS.events.connectionQuality.LOCAL_STATS_UPDATED, (stats) => this.statsHandler(stats));

		// Can use these events for debugging, not really helpful otherwise
		// room.on(JitsiMeetJS.events.conference.CONNECTION_ESTABLISHED, () => {});
		// room.on(JitsiMeetJS.events.conference.CONNECTION_INTERRUPTED, () => {});

		if (this.isAdmin) {
			room.on(JitsiMeetJS.events.conference.PARTICIPANT_PROPERTY_CHANGED, (updatedProperty) => {
				console.log("[!] Room event:: Participant property changed, the updated properties are as follows: ", updatedProperty);
			});
		}

		room.setReceiverVideoConstraint(720);
		room.setSenderVideoConstraint(720);

		room.setDisplayName(this.initOptions.userInfo.displayName);
		room.join();
	};

	onP2PStatusChanged = async (status) => {
		console.log("[!] P2P Status Changed:: Status values are as follows! ", status);
		try {
			console.log(`[!] P2P Status Changed:: This is ${status.p2p ? "" : "not "}a P2P call!`);
			if (status.p2p) {
				// Commenting this older method since this is breaking on chrome 122 version
				// this.localTracks.forEach(async (track) => await this.room.addTrack(track));

				// Adding a new way of handling the streams if streams are already added when room is joined but this intermediately breaks while trying to perform rejoin
				// const roomTracks = this.room.getLocalTracks();
				// this.localTracks.forEach(async (track) => {
				// 	try {
				// 		if (roomTracks?.length) {
				// 			const trackIdx = roomTracks.findIndex((t) => t?.track?.kind === track?.track?.kind && t?.track?.id === track?.track?.id);
				// 			if (trackIdx > -1) {
				// 				await this.room.replaceTrack(roomTracks[trackIdx], track);
				// 			} else {
				// 				await this.room.addTrack(track);
				// 			}
				// 		} else {
				// 			await this.room.addTrack(track);
				// 		}
				// 	} catch (err) {
				// 		console.error("Error while replacing tracks! ", err);
				// 		if (this.isAdmin) {
				// 			this.jitsiEvents("vueSnack", `Something went wrong while replacing tracks, please rejoin if the video is stuck!`);
				// 		}
				// 	}
				// });

				if (this.p2pRejoin) {
					if (this.localAudioMuted) {
						//If the existing audio was muted, mute it again
						this.muteTrack("audio");
					}
					if (this.localVideoMuted) {
						//If the existing video was muted, mute it again
						this.muteTrack("video");
					}
				}
			}
		} catch (err) {
			console.error("[!] P2P Status Changed:: Error while adding tracks! ", err);
			if (this.jitsiEvents && !this.disableJitsiLogs) {
				this.jitsiEvents("logs", `onP2PStatusChanged - ${err}`);
			}
		}
	};

	onConnectionFailed = (resp) => {
		console.error("[!] Connection Event:: Connection Failed! ", resp);

		if (this.jitsiEvents && !this.disableJitsiLogs) {
			this.jitsiEvents("logs", `onConnectionFailed - ${resp}`);
		}
	};

	onDisconnect = (resp) => {
		console.error("[!] Connection Event:: Connection Disconnected! ", resp);

		// if (this.jitsiEvents && !this.disableJitsiLogs) {
		// 	this.jitsiEvents("logs", `onDisconnect - ${resp}`);
		// }
	};

	retrySuccessful = () => {
		if (this.localAudioMuted) {
			//If the existing audio was muted, mute it again
			this.muteTrack("audio");
		}
		if (this.localVideoMuted) {
			//If the existing video was muted, mute it again
			this.muteTrack("video");
		}

		if (this.jitsiEvents && this.isAdmin) {
			//Calling the rejoin event for the enduser
			this.jitsiEvents("message", {
				event: "rejoin"
			});
		}
		if (this.jitsiEvents && !this.isAdmin) {
			//Once the enduser joins, this disables the vue loader for RE
			this.jitsiEvents("message", {
				event: "rejoined"
			});
		}

		setTimeout(() => {
			this.isRetryCase = false;
		}, 5000);
	};

	onLocalTracks = (tracks) => {
		//This part of the code adds the local tracks i.e. your own reflection is seen due to this code
		this.localTracks = tracks;

		for (let i = 0; i < this.localTracks.length; i++) {
			this.localTracks[i].addEventListener(JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED, (audioLevel) => console.log(`[!] Track Listener: Audio level local: ${audioLevel}`));
			this.localTracks[i].addEventListener(JitsiMeetJS.events.track.TRACK_MUTE_CHANGED, () => console.log("[!] Track Listener: Local track muted!"));
			this.localTracks[i].addEventListener(JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED, () => console.log("[!] Track Listener: Local track stopped!"));

			// We are attaching the generated local tracks to the html element
			if (this.localTracks[i].getType() === "video") {
				if (this.directLocal) {
					this.localTracks[i].attach($(`#${this.directLocal}`)[0]);
				} else {
					$(`#${this.htmlElement}`).append(`<video autoplay id='localVideo${i}' />`);
					this.localTracks[i].attach($(`#localVideo${i}`)[0]);
				}
			} else {
				$(`#${this.htmlElement}`).append(`<audio autoplay muted id='localAudio${i}' />`);
				this.localTracks[i].attach($(`#localAudio${i}`)[0]);
			}
		}
	};

	onRemoteTrack = (track) => {
		if (track.isLocal()) {
			return;
		}
		console.log(`[!] Room event:: Remote ${track.getType()} track added!`);

		const participant = track.getParticipantId();
		if (!this.remoteTracks[participant]) {
			this.remoteTracks[participant] = [];
		}

		const idx = this.remoteTracks[participant].push(track);
		console.log(`[!] Remote Track Array Details:: Track ID: ${track.getId()} | Length: ${idx} | Participant ID: ${participant}`);

		// Commenting this part of the code since this is restarting recording multiple times and causing flickering issue in the recording
		// For recording from admin end
		// if (this.isAdmin && this.isRecording) {
		// 	//This idx contains the length of the remote track array element
		// 	//So checking if the length is 2 because we need to make sure the audio and video both are present
		// 	if (idx >= 2) {
		// 		setTimeout(() => {
		// 			console.log("[!] Initiating recorder, setting remote tracks to chunks!");
		// 			this.workOnArrayStream(this.unMuteCb);
		// 		}, 500);
		// 	}
		// }

		if (this.isAdmin && this.isRecording && this.p2pRejoin) {
			if(idx === 4) {
				setTimeout(() => {
					console.log("[!] Initiating recorder, setting remote tracks to chunks!");
					this.workOnArrayStream(this.unMuteCb);
				}, 500);
			}
		}

		track.addEventListener(JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED, (audioLevel) => console.log(`[!] Remote Track Listener: Audio level remote: ${audioLevel}`));
		track.addEventListener(JitsiMeetJS.events.track.TRACK_MUTE_CHANGED, (track) => {
			console.log("[!] Remote Track Listener:: Remote track muted!");
			if (track.getType() === "video") {
				this.remoteVideoMuteCB(track.muted);
			}
			if (this.isAdmin && this.isRecording) {
				setTimeout(() => {
					console.log("[!] Remote track muted! Restarting video recording with muted track!");
					this.workOnArrayStream(this.unMuteCb);
				}, 500);
			}
		});
		track.addEventListener(JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED, () => console.log("[!] Remote Track Listener: Remote track Stopped!"));

		const id = participant + track.getType() + idx;

		if (track.getType() === "video") {
			if (this.directRemote) {
				track.attach($(`#${this.directRemote}`)[0]);
			} else {
				$(`#${this.htmlElement}`).append(`<video autoplay id='${participant}video${idx}' />`);
				track.attach($(`#${id}`)[0]);
			}
		} else {
			$(`#${this.htmlElement}`).append(`<audio autoplay id='${participant}audio${idx}' />`);
			track.attach($(`#${id}`)[0]);
		}
	};

	onConferenceJoined = async () => {
		console.log("[!] Jitsi event:: Conference Joined!");

		try {
			//Adding the created tracks to the room, these tracks will be replaced once p2p connection is established
			// this.localTracks.forEach(async (track) => await this.room.addTrack(track));

			// this.room.setReceiverConstraints({
			// 	colibriClass: "ReceiverVideoConstraints",
			// 	onStageSources: ["A-v0"],
			// 	defaultConstraints: { maxHeight: 480 },
			// 	constraints: {
			// 		"A-v0": { maxHeight: 720 },
			// 		"A-v1": { maxHeight: 480 },
			// 		"A-v2": { maxHeight: 360 }
			// 	}
			// });

			if (this.isRetryCase) {
				this.retrySuccessful();
			}
		} catch (err) {
			console.error("[!] onConferenceJoined:: Error while adding local tracks to the room!", err);
		}
	};

	onUserJoin = (id, details) => {
		console.log("[!] Room event:: User Joined with Id: ", id, "and details:  ", details._displayName);

		this.remoteTracks[id] = [];

		if (this.jitsiEvents && this.isAdmin) {
			if (this.isRetryCase || this.p2pRejoin) {
				this.jitsiEvents("vueSnack", `${details._displayName || "End user"} reconnected!`);
			} else {
				this.jitsiEvents("vueSnack", `${details._displayName || "End user"} has joined!`);
			}
		}
	};

	onUserLeft = async (id, details) => {
		console.log(`[!] Room event:: User has left with Id: ${id} and details: ${details._displayName}`);

		if (this.jitsiEvents && this.isAdmin && !this.isRetryCase && !this.p2pRejoin) {
			this.jitsiEvents("vueSnack", `${details._displayName || "End user"} has left the room!`);
		}

		if (this.remoteTracks[id]) {
			delete this.remoteTracks[id];
		}
	};

	muteTrack = (trackType) => {
		try {
			console.log(`[!] muteTrack:: muting ${trackType}!`);

			this.localTracks.forEach((track) => {
				if (track.getType() == trackType && !track.isMuted()) {
					track.mute();
					if (trackType === "audio") {
						this.localAudioMuted = true;
					} else if (trackType === "video") {
						this.localVideoMuted = true;
					}
				}
			});
		} catch (err) {
			console.error(`[!] muteTrack:: Error while muting ${trackType}! `, err);
		}
	};

	unMuteTrack = (trackType) => {
		try {
			console.log(`[!] unMuteTrack:: unmuting ${trackType}!`);

			this.localTracks.forEach((track) => {
				if (track.getType() == trackType && track.isMuted()) {
					track.unmute();

					if (this.isAdmin && this.isRecording) {
						setTimeout(() => {
							console.log("[!] Local Track un-muted and this, hence we are printing this!");
							this.workOnArrayStream(this.unMuteCb);
						}, 500);
					}

					if (trackType === "audio") {
						this.localAudioMuted = false;
					} else if (trackType === "video") {
						this.localVideoMuted = false;
					}
				}
			});
		} catch (err) {
			console.error(`[!] unMuteTrack:: Error while unmuting ${trackType}! `, err);
		}
	};

	switchCamera = () => {
		return new Promise(async (resolve, reject) => {
			try {
				this.facingModeUser = !this.facingModeUser;

				this.tempTrack = this.localTracks.pop();
				try {
					!this.tempTrack.isMuted() && this.tempTrack.mute();
					//This is used to release the existing stream of front or back camera
					await this.tempTrack.dispose();
				} catch (err) {
					console.error("[!] switchCamera:: Error while disposing existing track! ", err);
					if (this.jitsiEvents && !this.disableJitsiLogs) {
						this.jitsiEvents("logs", `switchCamera error while disposing existing local track - ${err}`);
					}
				}

				//The second parameter is for permission triggered or not
				await JitsiMeetJS.createLocalTracks(this.generateTrackOptions("onlyVideo"), true)
					.then(async (tracks) => {
						const idx = this.localTracks.push(tracks[0]) - 1;

						this.localTracks[idx].addEventListener(JitsiMeetJS.events.track.TRACK_MUTE_CHANGED, () => console.log("[!]Track Listener: Local track muted!"));
						this.localTracks[idx].addEventListener(JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED, () => console.log("[!]Track Listener: Local track stopped!"));

						if (this.directLocal) {
							this.localTracks[idx].attach($(`#${this.directLocal}`)[0]);
						} else {
							$(`#${this.htmlElement}`).append(`<video autoplay id='localVideo1' />`);
							this.localTracks[idx].attach($("#localVideo1")[0]);
						}

						try {
							await this.room.replaceTrack(this.tempTrack, this.localTracks[idx]);
						} catch (err) {
							console.error("[!] switchCamera > CreateLocalTracks:: Error while replacing track! ", err);
							if (this.jitsiEvents && !this.disableJitsiLogs) {
								this.jitsiEvents("logs", `switchCamera createlocaltracks stream replace error - ${err}`);
							}
							await this.room.addTrack(this.localTracks[idx]);
						}

						this.tempTrack = undefined;
						resolve();
					})
					.catch(async (err) => {
						console.error("[!] switchCamera > CreateLocalTracks:: Error while creating tracks! ", err);
						if (this.jitsiEvents && !this.disableJitsiLogs) {
							this.jitsiEvents("logs", `switchCamera createlocaltracks error - ${err}, the device ids are- front=${this.frontCameraId} and back=${this.backCameraId}`);
						}
						await this.setLocalDeviceIds();
						reject();
					});
			} catch (err) {
				console.error("[!] switchCamera:: Error while creating tracks! ", err);
				reject();
			}
		});
	};

	leaveRoom = async (callEnded = false) => {
		return new Promise(async (resolve, reject) => {
			try {
				this.p2pRejoin = true;

				if(callEnded){
					this.isRecording = false;
				}

				try {
					//Removing tracks from room
					this.localTracks.forEach(async (track) => await this.room.removeTrack(track));
				} catch (err) {
					console.error("[!] LeaveRoom:: Error while removing tracks from room! ", err);
					if (this.jitsiEvents && !this.disableJitsiLogs) {
						this.jitsiEvents("logs", `leaveRoom - error while removing track - ${err}`);
					}
				}

				await this.room.leave();
				await this.connection.disconnect();

				//Track removal and release
				this.localTracks.forEach(async (track) => await track.dispose());
				resolve();
			} catch (err) {
				console.error("[!] LeaveRoom:: Error while leaving room! ", err);
				reject(err);
			}
		});
	};

	reconnectRoom = async () => {
		return new Promise(async (resolve, reject) => {
			try {
				//Flags reset
				this.localTracks.length = 0;
				this.remoteTracks = {};
				this.facingModeUser = true;
				this.backCameraId = undefined;
				this.frontCameraId = undefined;

				//This is crashing mobile browsers
				// setTimeout(() => {
				// 	this.p2pRejoin = false;
				// }, 15000);

				//Re-initializing jitsimeet as well
				JitsiMeetJS.init(this.initOptions);
				JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels[this.jitsiLogLevel]);

				// Recreating connection once again and setting up all the parameters
				this.connection = new JitsiMeetJS.JitsiConnection(null, null, this.connectionOptions);
				this.initiateConnection(this.connection);
				resolve();
			} catch (err) {
				console.error("[!] Reconnect:: Error while reconnecting to room! ", err);
				reject(err);
			}
		});
	};

	workOnArrayStream = (cbFunction) => {
		if(!this.isRecording) {
			console.warn("Stopping recording recursion since the recording flag is set to false.");
			return;
		}
		//Making sure the arrayStreams are emptied out before pushing in new streams
		this.arrayStream = [];

		let localAudio = undefined,
			localVideo = undefined,
			remoteAudio = undefined,
			remoteVideo = undefined;

		this.localTracks.forEach((track) => {
			if (track.getType() == "audio") {
				localAudio = track;
			} else if (track.getType() == "video") {
				localVideo = track;
			}
		});

		//getting remote tracks
		for (let i in this.remoteTracks) {
			this.remoteTracks[i].forEach((track) => {
				if (track.getType() == "audio" && !track.disposed) {
					remoteAudio = track;
				} else if (track.getType() == "video" && !track.disposed) {
					remoteVideo = track;
				}
			});
		}

		if (localVideo && localVideo.stream) {
			this.arrayStream.push(localVideo.stream);
		}
		if (localAudio && localAudio.stream) {
			this.arrayStream.push(localAudio.stream);
		}
		if (remoteVideo && remoteVideo.stream) {
			this.arrayStream.push(remoteVideo.stream);
		}
		if (remoteAudio && remoteAudio.stream) {
			this.arrayStream.push(remoteAudio.stream);
		}

		if (this.arrayStream.length == 4) {
			this.recordingErrCount = 0;
			if (cbFunction) {
				cbFunction(this.arrayStream);
			}
		} else {
			this.recordingErrCount++;
			console.warn("[!] Failed in pulling 4 streams, automatically retrying in 3000 ms! ", this.remoteTracks);

			if (this.jitsiEvents && this.isAdmin && this.isRecording && this.recordingErrCount >= 5) {
				this.recordingErrCount = 0;
				this.jitsiEvents("vueSnack", `Failed to acquire streams for recording, please try rejoining the call!`);
			}

			if (cbFunction) {
				cbFunction(this.arrayStream);
			}
			setTimeout(() => {
				this.workOnArrayStream(cbFunction);
			}, 3000);
		}
	};

	// cannot keep retrying, otherwise ghost participants will be created again and again
	// it takes 70 seconds for a ghost participant to leave the room - automatically
	isValidRetry = () => {
		try {
			if (this.room) {
				// participants() only gives number of remote participants
				let participants = this.room.getParticipants();
				for (let itr = 0; itr < participants.length; itr++) {
					// This check if participant already exists in the room to avoid spawning ghost participants
					if (this.initOptions["userInfo"] && participants[itr]._displayName == this.initOptions["userInfo"].displayName) {
						return false;
					}
				}
				return true;
			}
			return false;
		} catch (err) {
			console.error("[!] isValidRetry:: Error while validating retry! ", err);
		}
	};

	retryConnecting = async () => {
		try {
			if (!this.isValidRetry()) {
				// This message is called to disable the vueLoader
				if (this.jitsiEvents && !this.isAdmin) {
					this.jitsiEvents("message", {
						event: "rejoined"
					});
				}
				return;
			}

			let tracks = this.room.getLocalTracks();

			if (tracks?.length > 0) {
				try {
					tracks.forEach(async (track) => await track.dispose());

					// Setting the value of localTracks back to new array
					this.localTracks.length = 0;
				} catch (err) {
					console.error("[!] Retry block:: Error while removing/disposing track! ", err);
				}
			} else {
				this.localTracks.length = 0;
			}

			// This flag is essential for retrying
			this.isRetryCase = true;

			this.remoteTracks = {};
			this.facingModeUser = true;

			try {
				await this.room.leave();
				await this.connection.disconnect();
			} catch (err) {
				console.error("[!] Retry block:: Error while leaving room/disconnecting connection! ", err);
			}

			// Recreating connection once again and setting up all the parameters
			this.connection = new JitsiMeetJS.JitsiConnection(null, null, this.connectionOptions);
			this.initiateConnection(this.connection);
		} catch (err) {
			console.error("[!] Retry block:: Error while retrying! ", err);
		}
	};

	// TODO: This is a feature for future, uncomment and use if required.. Make sure to take care of the recording while taking this into account
	// stopScreenShare = () => {
	// 	return new Promise(async (resolve) => {
	// 		try {
	// 			this.isScreenShared = !this.isScreenShared;

	// 			await this.room.removeTrack(this.localTracks[2]);

	// 			await this.sleep(300); //It is important to wait a bit before the event can be registered to the listener

	// 			//Disposing and removing the share screen track
	// 			if (this.localTracks[2]) {
	// 				await this.localTracks[2].dispose();
	// 				this.localTracks.pop();
	// 			}

	// 			if (this.screenShareAudioStream[0]) {
	// 				await this.screenShareAudioStream[0].dispose();
	// 				this.screenShareAudioStream.pop();
	// 			}

	// 			if (!this.localVideoMuted) {
	// 				this.localTracks[1].unmute();
	// 			}

	// 			if (this.directLocal) {
	// 				this.localTracks[1].attach($(`#${this.directLocal}`)[0]);
	// 			} else {
	// 				this.localTracks[1].attach($("#localVideo1")[0]);
	// 			}

	// 			this.room.addTrack(this.localTracks[1]);

	// 			resolve();
	// 		} catch (err) {
	// 			console.log("Error while stopping screenshare! ", err);
	// 			resolve();
	// 		}
	// 	});
	// };

	//TODO: This is a feature for future, uncomment and use if required
	// startScreenShare = () => {
	// 	return new Promise(async (resolve, reject) => {
	// 		this.isScreenShared = !this.isScreenShared;

	// 		//The second parameter is for permission triggered or not
	// 		await JitsiMeetJS.createLocalTracks(this.generateTrackOptions("screenShare"))
	// 			.then(async (tracks) => {
	// 				if (!this.localVideoMuted) {
	// 					this.localTracks[1].mute();
	// 				}

	// 				await this.room.removeTrack(this.localTracks[1]);

	// 				await this.sleep(300); //It is important to wait a bit before the event can be registered to the listener

	// 				if (tracks.length === 2) {
	// 					this.screenShareAudioStream.push(tracks[0]);
	// 					this.localTracks.push(tracks[1]);
	// 				} else {
	// 					this.localTracks.push(tracks[0]);
	// 				}

	// 				this.localTracks[2].addEventListener(JitsiMeetJS.events.track.TRACK_MUTE_CHANGED, () => console.log("[!]Track Listener: Local track muted!"));
	// 				this.localTracks[2].addEventListener(JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED, () => console.log("[!]Track Listener: Local track stopped!"));
	// 				this.localTracks[2].stream.getVideoTracks()[0].addEventListener("ended", async () => {
	// 					await this.stopScreenShare();
	// 					this.screenSharingStatus(false);
	// 				});

	// 				if (this.directLocal) {
	// 					this.localTracks[2].attach($(`#${this.directLocal}`)[0]);
	// 				} else {
	// 					this.localTracks[2].attach($("#localVideo1")[0]);
	// 				}

	// 				this.room.addTrack(this.localTracks[2]);

	// 				resolve();
	// 			})
	// 			.catch((err) => {
	// 				console.log("Error while getting screen details! ", err);
	// 				reject();
	// 			});
	// 	});
	// };
}

module.exports = {
	SignzyVideoService
};
